<template>
  <div :class="type">
    <shared-show :type="type" :inputs="inputs" :lefts="leftCards" :rights="rightCards" />
  </div>
</template>

<script>
import SharedShow from '@/components/shared/show.vue'

export default {
  name: 'TourDetail',
  components: {
    SharedShow
  },
  data() {
    return {
      type: 'tour',
      inputs: ['t_name', 'price', 'distance', 'description', 'content', 'agent_info', 'policy', 'tip_hour'],
      leftCards: ['schedule', 'groups'],
      rightCards: [
        'packages',
        'display',
        'address',
        'contact',
        'category',
        'place',
        'youtube',
        'audios',
        'pictures',
        'videos'
      ]
    }
  }
}
</script>

<style lang="scss" scoped></style>
